<template>
	<div class="fill-height fill-width" style="overflow: hidden">
		<v-container
			fluid 
			style="height: calc(100vh - 60px);" 
		>
			<div
				class="px-7 pt-7"
			>
				<v-row no-gutters>
					<v-col cols="12" md="auto" class="title mb-1">
						<span
							@click="$router.push('/new-order')"
						>
							Vehicle Information
						</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span
							@click="$router.push('/new-order/select-seat')"
						>
							Select Seat Model
						</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="backToCustomizationPage()">Customize Seat</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="$router.push('/new-order/order-options')">Options</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span class="black--text">View 2D</span>
					</v-col>
				</v-row>
			</div>
			<div>
				<v-row
					class="fill-width fill-height"
				>
					<v-col 
						cols="12"
					>
						<div 
							v-if="pdfPreview.path"
							style="overflow: hidden"
						>
							<iframe
								:src="pdfPreview.pdf_reader_friendly_path"
								width="100%"
								height="100%"
								style="overflow: hidden"
								frameBorder="0"
								scrolling="no" 
							>
							</iframe>
							<!-- <object
								:data="pdfPreview ? pdfPreview.path : null"
								style="height: 100%; width: 100%;"
								type="text/html"
							>
							</object> -->
							<!-- <v-btn 
								v-else
								class="ma-2" 
								text 
								:href="pdfPreview ? pdfPreview.path : null"
								download
							>
								Click to download 2D PDF
							</v-btn> -->
						</div>
						<div
							v-else
							style="margin-top: 18%; margin-bottom: 18%; margin-left: 32%;"
						>
							<p class="font-weight-bold" style="font-size: 2em">2D preview for this package is currently unavailable.</p>
						</div>
					</v-col>
				</v-row>
				
				<v-row justify="end">
					<v-col 
						cols="12" md="3"
						style="position: fixed; bottom: 0px; right: 5px"
					>
						<v-btn
							block
							depressed
							color="primary"
							class="text-capitalize secondary--text radius-10 mb-5"
							@click="nextStage()"
						>
							Continue - View 3D
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</div>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { searchMixin } from "@/mixins/SearchMixin"
import { mapGetters, mapActions } from "vuex"

const FILE_TYPE_PDF = 2

export default {
	components:{

	},
	mixins: [
		resourceMixin,
		searchMixin,
		errorHandlerMixin
	],
	props:{
	},
	data(){
		return {
			pdfPreview: {
				path: null
			},
		}
	},
	computed:{
		...mapGetters(["orderCustomizations", "selectedSeatModel"])
	},
	activated(){
		if(Object.keys(this.orderCustomizations).length === 0)
			this.backToCustomizationPage()

		this.getPdfPreview()
		this.scrollToTop()
		this.setIsCustomization(true)
	},
	created(){
	},
	methods:{
		...mapActions(["setIsCustomization"]),
		toCustomizingStage()
		{
			this.$router.push("/new-order/customization")
		},
		toOrderOptionStage()
		{
			this.$router.push("/new-order/order-options")
		},
		nextStage()
		{
			this.setIsCustomization(false)
			this.$router.push("/new-order/customization")
		},
		getPdfPreview()
		{
			this.pdfPreview = this.selectedSeatModel.file_uploads.find((item)=> item.file_upload_type_id == FILE_TYPE_PDF && item.package_id == this.orderCustomizations.package_id)
		},
		scrollToTop() {
			window.scrollTo(0,0);
		},
		backToCustomizationPage()
		{
			this.setIsCustomization(true)
			this.$router.push('/new-order/customization')
		}

	}
}
</script>
